<template>
 <v-layout align-center justify-center fill-height>
    <v-container>
        <v-form ref="form-add" v-on:submit.prevent v-model="loginForm.valid" v-on:keydown.enter="loginUser">
          <v-card class="mx-auto" max-width="420">
            <v-img class="white--text align-end" height="340px" src="../assets/images/WebBanner.jpg" position="center center">
            </v-img>

            <v-card-title class="text-left">
              Common Production Assets Management System
            </v-card-title>

            <v-card-text>
              <v-text-field v-model="loginForm.model.username" :append-icon="hasCamera ? 'mdi-barcode-scan' : null" v-on:click:append="toggleScanner" :rules="loginForm.usernameRules" label="Username / Code" v-on:keydown.enter="loginUser"></v-text-field>
              <StreamBarcodeReader v-if="loginForm.showScanner" v-on:decode="onScannerDecode"></StreamBarcodeReader>
              <v-text-field v-model="loginForm.model.password" :append-icon="loginForm.showCode ? 'mdi-eye' : 'mdi-eye-off'" :rules="loginForm.passwordRules" :type="loginForm.showPassword ? 'text' : 'password'" label="Password" v-on:click:append="loginForm.showPassword = !loginForm.showPassword" v-on:keydown.enter="loginUser"></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!loginForm.valid" color="primary" v-on:click="loginUser">
                登入 Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-container>
    </v-layout>
</template>

<script>
export default {
  name: 'Login',
  data: function () {
    return {
      loginForm: {
        valid: false,
        model: {
          username: null,
          password: null,
        },
        showPassword: false,
        showScanner: false,
        usernameRules: [
          v => !!v || 'Required'
        ],
        passwordRules: [
        ]
      },
    }
  },
  mounted() {
    if(this.auth){
      this.navTo('/Dashboard')
    }
  },
  methods: {
    loginUser: function () {
      if(!this.loginForm.valid) return;
      var _this = this;
      this.post('/auth/login', this.loginForm.model, function(res){
        _this.loginForm.model.username = null;
        _this.loginForm.model.password = null;
        _this.auth = res['data']['data'];
        _this.axios.defaults.headers.common['Authorization'] = `Bearer ${_this.auth.access_token}`;
        _this.axiosBG.defaults.headers.common['Authorization'] = `Bearer ${_this.auth.access_token}`;
        _this.setCookie("token", _this.auth.access_token, 10/60/24);

        localStorage['auth'] = JSON.stringify(_this.auth);
        _this.navTo('Dashboard')
      }, null, {showSuccess: false, showError: true, showLoading: true});
    },
    toggleScanner: function() {
      this.loginForm.showScanner = !this.loginForm.showScanner;
    },
    onScannerDecode: function(result) {
      // var _this = this;
      this.loginForm.model.username = result
      this.loginForm.showScanner = false;

      setTimeout(() => {
        this.loginUser();
      }, 50);
    }
  }
}
</script>